import {DocumentRepositoryConfiguration} from './documentrepositoryconfiguration';
import {DefaultEntity} from './defaultEntity';
import {Pageable} from './pageble';
import {Product} from './product';
import {SlaDefinition} from './slaDefinition';
import {JiraData} from './jiraData';
import {Iteration} from './iteration';
import {QCChecklist} from './qcChecklist';
import {User} from './user';
import {RequestType} from './requestType';

export enum IterationRequestStatus {
  Backlog = 'BACKLOG',
  InProgress = 'IN_PROGRESS',
  Done = 'DONE',
  Canceled = 'CANCELED'
}

export enum IterationRequestResult {
  Passed = 'PASSED',
  Failed = 'FAILED'
}

export enum IterationRequestPriority {
  Showstopper = 'SHOWSTOPPER',
  High = 'HIGH',
  Medium = 'MEDIUM',
  Low = 'LOW',
  Minor = 'MINOR'
}

export class IterationRequest extends DefaultEntity {
  product: Product;
  status: IterationRequestStatus;
  result: IterationRequestResult;
  startDate: Date;
  finishDate: Date;
  slaDefinition: SlaDefinition;
  originalRequestUrl: string;
  originalDocumentUrl: string;
  documentName: string;
  requestType: RequestType;
  incomingDate: Date;
  returnDate: Date;
  repositoryConfiguration: DocumentRepositoryConfiguration;
  jira: JiraData;
  iteration: Iteration;
  qcChecklist: QCChecklist;
  reviewer: User;
  priority: IterationRequestPriority;
  iterationNumber: number;
}

export interface IterationRequestPageable extends Pageable {
  content: IterationRequest[];
}
